import React from "react";
import "./OverlayAd.css";

const OverlayAd = ({ adHtml, onClose }) => {
  return (
    <div className="overlay-ad">
      <div className="overlay-ad-content">
        <button className="overlay-ad-close" onClick={onClose}>
          ×
        </button>
        <div dangerouslySetInnerHTML={{ __html: adHtml }}></div>
      </div>
    </div>
  );
};

export default OverlayAd;
