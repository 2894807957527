import React from "react";
import "./HomePage.css";

const HomePage = () => {
  return (
    <div className="homepage">
      <header className="homepage-header">
        <h1 className="homepage-title">Welcome to TrendTagger</h1>
        <p>Your go-to source for Instagram trend insights.</p>
      </header>
      <section className="homepage-content">
        <p>
          <strong>TrendTagger</strong>は、<strong>Instagram</strong>
          の人気ハッシュタグといいね数を元にしたランキングサイトです。
          各ハッシュタグごとの投稿ランキングや、ハッシュタグの分析情報を提供します。
        </p>
        <p>当サイトでは、以下の機能を提供しています：</p>
        <ul>
          <li>主要ハッシュタグの投稿ランキング</li>
          <li>ハッシュタグ分析ページ</li>
          <li>人気のハッシュタグ検索</li>
        </ul>
        <p>
          <strong>ファッション</strong>、<strong>ビューティー</strong>、
          <strong>フィットネス</strong>、<strong>フード</strong>、
          <strong>生活用品</strong>、<strong>テクノロジー</strong>
          など、様々なジャンルの投稿をカバーしています。
        </p>
      </section>
    </div>
  );
};

export default HomePage;
