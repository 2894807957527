import React, { createContext, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import config from "../config";

export const HashtagContext = createContext();

export const HashtagProvider = ({ children }) => {
  const [currentHashtags, setCurrentHashtags] = useState([]);
  const [, setRetainState] = useState(false); // 新しいフラグ
  const location = useLocation();

  useEffect(() => {
    // デフォルトのハッシュタグをセット
    setCurrentHashtags(config.defaultHashtags);
  }, []);

  useEffect(() => {
    // retainStateがfalseの場合、ページ遷移時に検索結果をリセット
    if (!location.state?.retainSearch) {
      setCurrentHashtags(config.defaultHashtags);
    }
    setRetainState(false); // 次のページ遷移でリセットするためにフラグを戻す
  }, [location]);

  const handleTopPostsSearch = (hashtag) => {
    setCurrentHashtags([hashtag]);
  };

  const handleAnalyticsSearch = (hashtag) => {
    setCurrentHashtags([hashtag]);
  };

  const setRetainSearchState = (state) => {
    setRetainState(state);
  };

  return (
    <HashtagContext.Provider
      value={{
        currentHashtags,
        handleTopPostsSearch,
        handleAnalyticsSearch,
        setRetainSearchState, // フラグを設定する関数を提供
      }}
    >
      {children}
    </HashtagContext.Provider>
  );
};
