import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import "./SearchBar.css";

const SearchBar = ({ label, placeholder, onSubmit }) => {
  const [inputValue, setInputValue] = useState("");
  const location = useLocation();

  useEffect(() => {
    // ページ遷移時に検索窓の内容をリセット
    setInputValue("");
  }, [location]);

  const handleSubmit = (event) => {
    event.preventDefault();
    onSubmit(inputValue);
  };

  return (
    <div className="search-bar">
      <h3>{label}</h3>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          placeholder={placeholder}
        />
        <button type="submit">検索</button>
      </form>
    </div>
  );
};

export default SearchBar;
