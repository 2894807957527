import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Pie } from "react-chartjs-2";
import "chartjs-adapter-date-fns";
import { Chart, registerables } from "chart.js";
import SearchBar from "../SearchBar/SearchBar";
import LoadingIndicator from "../LoadingIndicator/LoadingIndicator";
import config from "../../config";
import "./PopularHashtags.css";
import AdCard from "../AdCard/AdCard";
import OverlayAd from "../AdCard/OverlayAd";
import NoResultsMessage from "../NoResultsMessage/NoResultsMessage";

Chart.register(...registerables);

const PopularHashtags = () => {
  const [chartData, setChartData] = useState({});
  const [currentHashtags, setCurrentHashtags] = useState(
    config.defaultHashtags
  );
  const [showAll, setShowAll] = useState({});
  const [loading, setLoading] = useState(true);
  const [authError, setAuthError] = useState(false); // 401エラーの状態を追加
  const [showOverlayAd, setShowOverlayAd] = useState(false);
  const navigate = useNavigate(); // useNavigateを使用してページ遷移を行う

  const SEARCH_THRESHOLD = 3;

  useEffect(() => {
    setLoading(true);
    fetchAllHashtagData(currentHashtags);
  }, [currentHashtags]);

  const fetchAllHashtagData = async (hashtags) => {
    let fetchCount = 0;

    try {
      // CSRFトークンを取得
      await axios.get(`${config.apiBaseUrl}/sanctum/csrf-cookie`, {
        withCredentials: true,
      });

      hashtags.forEach(async (hashtag) => {
        const isDefaultHashtag = config.defaultHashtags.includes(hashtag);
        const url = isDefaultHashtag
          ? `${config.apiBaseUrl}/api/popular-hashtags/${hashtag}`
          : `${config.apiBaseUrl}/api/popular-hashtags/secure/${hashtag}`;

        try {
          const response = await axios.get(url, {
            withCredentials: true,
            headers: {
              Accept: "application/json",
            },
          });

          if (response.data && response.data.length > 0) {
            const sortedData = response.data
              .sort((a, b) => b.count - a.count)
              .slice(0, 100);
            const labels = sortedData.map((item) => item.tag);
            const counts = sortedData.map((item) => item.count);

            setChartData((prevData) => ({
              ...prevData,
              [hashtag]: {
                labels: labels,
                datasets: [
                  {
                    label: `Hashtags associated with #${hashtag}`,
                    data: counts,
                    backgroundColor: labels.map(
                      (_, i) => `hsl(${(i * 360) / labels.length}, 70%, 50%)`
                    ),
                    borderWidth: 1,
                  },
                ],
              },
            }));
          } else {
            setChartData((prevData) => ({
              ...prevData,
              [hashtag]: {},
            }));
          }
        } catch (error) {
          if (error.response && error.response.status === 401) {
            setAuthError(true); // 401エラーが発生した場合、authErrorをtrueに設定
          }
          console.error("Error fetching data:", error);
        } finally {
          fetchCount += 1;
          if (fetchCount === hashtags.length) {
            setLoading(false);
          }
        }
      });
    } catch (error) {
      console.error("Error fetching CSRF cookie or posts:", error);
      setLoading(false);
    }
  };

  const handleSearch = (newHashtag) => {
    setCurrentHashtags([newHashtag]);
    setShowAll({});
    const searchCount = parseInt(
      localStorage.getItem("searchCount") || "0",
      10
    );
    localStorage.setItem("searchCount", searchCount + 1);

    if ((searchCount + 1) % SEARCH_THRESHOLD === 0) {
      setShowOverlayAd(true);
    }
  };

  const handleHashtagClick = (hashtag) => {
    setCurrentHashtags([hashtag]);
    setShowAll({});
  };

  const toggleShowAll = (hashtag) => {
    setShowAll((prevShowAll) => ({
      ...prevShowAll,
      [hashtag]: !prevShowAll[hashtag],
    }));

    if (!showAll[hashtag]) {
      setTimeout(() => {
        document
          .getElementById(`chart-${hashtag}`)
          .scrollIntoView({ behavior: "smooth" });
      }, 0);
    }
  };

  const getRandomAd = () => {
    const randomIndex = Math.floor(
      Math.random() * config.adDetailsForOtherPages.length
    );
    return config.adDetailsForOtherPages[randomIndex];
  };

  const getRandomOverlayAd = () => {
    const randomIndex = Math.floor(
      Math.random() * config.overlayAdDetails.length
    );
    return config.overlayAdDetails[randomIndex];
  };

  return (
    <div className="popular-hashtags">
      {loading ? (
        <LoadingIndicator />
      ) : (
        <>
          <SearchBar
            label="人気のハッシュタグを検索"
            placeholder="ハッシュタグを入力"
            onSubmit={handleSearch}
          />
          {authError ? (
            <NoResultsMessage message="ログインが必要です。ログインまたは新規登録をしてください。" />
          ) : (
            currentHashtags.map((hashtag, index) => (
              <React.Fragment key={hashtag}>
                <div id={`chart-${hashtag}`} className="chart-container">
                  <h3>「#{hashtag}」と一緒に付けられているハッシュタグ</h3>
                  {chartData[hashtag] &&
                  chartData[hashtag].labels &&
                  chartData[hashtag].labels.length > 0 ? (
                    <>
                      <div className="chart-wrapper">
                        <Pie
                          data={chartData[hashtag]}
                          options={{
                            responsive: true,
                            maintainAspectRatio: false,
                            plugins: {
                              legend: {
                                display: false,
                              },
                            },
                            layout: {
                              padding: {
                                top: 20,
                                bottom: 20,
                              },
                            },
                          }}
                          width={null}
                          height={null}
                        />
                      </div>
                      <div className="hashtag-list">
                        {(showAll[hashtag]
                          ? chartData[hashtag].labels
                          : chartData[hashtag].labels.slice(0, 20)
                        ).map((label, index) => (
                          <div
                            key={index}
                            className="hashtag-item"
                            onClick={() => handleHashtagClick(label)}
                          >
                            <span
                              className="hashtag-color"
                              style={{
                                backgroundColor:
                                  chartData[hashtag].datasets[0]
                                    .backgroundColor[index],
                              }}
                            ></span>
                            {label}
                          </div>
                        ))}
                      </div>
                      <button
                        className="show-more-button"
                        onClick={() => toggleShowAll(hashtag)}
                      >
                        {showAll[hashtag] ? "隠す" : "もっとみる"}
                      </button>
                    </>
                  ) : (
                    <NoResultsMessage />
                  )}
                </div>
                {(index + 1) % 2 === 0 && (
                  <AdCard adHtml={getRandomAd().adHtml} />
                )}
              </React.Fragment>
            ))
          )}
          {authError && (
            <div className="auth-prompt">
              <button className="auth-button" onClick={() => navigate("/login")}>ログイン</button>
              <button className="auth-button" onClick={() => navigate("/register")}>新規登録</button>
            </div>
          )}
          {showOverlayAd && (
            <OverlayAd
              adHtml={getRandomOverlayAd().adHtml}
              onClose={() => setShowOverlayAd(false)}
            />
          )}
          <AdCard adHtml={getRandomAd().adHtml} />
        </>
      )}
    </div>
  );
};

export default PopularHashtags;
