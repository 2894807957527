import React, { useState, useEffect, useCallback } from "react";
import Fab from "@mui/material/Fab";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import "./ScrollToTopButton.css";

const ScrollToTopButton = () => {
  const [showScroll, setShowScroll] = useState(false);
  const [showButton, setShowButton] = useState(true);

  const checkScrollTop = useCallback(() => {
    if (!showScroll && window.pageYOffset > 300) {
      setShowScroll(true);
    } else if (showScroll && window.pageYOffset <= 300) {
      setShowScroll(false);
    }
  }, [showScroll]);

  const checkPageHeight = useCallback(() => {
    if (document.documentElement.scrollHeight > window.innerHeight + 100) {
      setShowButton(true);
    } else {
      setShowButton(false);
    }
  }, []);

  const scrollTop = useCallback(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", checkScrollTop);
    window.addEventListener("resize", checkPageHeight);
    checkPageHeight();
    return () => {
      window.removeEventListener("scroll", checkScrollTop);
      window.removeEventListener("resize", checkPageHeight);
    };
  }, [checkScrollTop, checkPageHeight]);

  if (!showButton) {
    return null;
  }

  return (
    <div className="scroll-to-top">
      {showScroll && (
        <Fab
          className="scroll-top"
          onClick={scrollTop}
          color="primary"
          aria-label="scroll to top"
        >
          <KeyboardArrowUpIcon />
        </Fab>
      )}
    </div>
  );
};

export default ScrollToTopButton;
