import React from "react";
import { NavLink } from "react-router-dom";
import "./Footer.css";

const Footer = () => {
  return (
    <footer className="App-footer">
      <nav>
        <NavLink to="/homepage" exact activeClassName="active">
          サイト概要
        </NavLink>
        <NavLink to="/" exact activeClassName="active">
          投稿ランキング
        </NavLink>
        <NavLink to="/analytics" activeClassName="active">
          投稿分析
        </NavLink>
        <NavLink to="/popular-hashtags" activeClassName="active">
          人気のハッシュタグ
        </NavLink>
        <NavLink to="/privacy-policy" activeClassName="active">
          プライバシーポリシー
        </NavLink>
      </nav>
      <p>&copy; 2024 Trend Tagger. All rights reserved.</p>
    </footer>
  );
};

export default Footer;
