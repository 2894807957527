import React from "react";
import "./NoResultsMessage.css";

const NoResultsMessage = ({ message }) => {
  return (
    <div className="no-results-message">
      <p>{message || "検索結果が見当たりません。"}</p>
    </div>
  );
};

export default NoResultsMessage;
