import React from "react";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import config from "../../config";
import { useNavigate } from "react-router-dom";
import "./GoogleLoginButton.css";

const GoogleLoginButton = () => {
  const navigate = useNavigate();

  const googleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      try {
        const googleLoginResponse = await axios.post(
          `${config.apiBaseUrl}/google-login`,
          { credential: tokenResponse.credential },
          { withCredentials: true }
        );

        console.log("Google login successful", googleLoginResponse);

        // レスポンスからユーザー名を取得し、localStorageに保存
        const { username } = googleLoginResponse.data;
        if (username) {
          localStorage.setItem("user", JSON.stringify({ username }));
        }

        // ダッシュボードにリダイレクト
        navigate("/dashboard");
      } catch (error) {
        console.error("Google login failed", error);
      }
    },
    onError: (error) => {
      console.error("Google Login Failure:", error);
    },
  });

  return (
    <button className="custom-google-button" onClick={() => googleLogin()}>
      <img
        src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg"
        alt="Google Logo"
        className="google-icon"
        width="20"
      />
      Googleでログイン
    </button>
  );
};

export default GoogleLoginButton;
