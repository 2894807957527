import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import "./Header.css";
import axios from "axios";
import config from "../../config";
import Cookies from "js-cookie";

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [username, setUsername] = useState("ゲスト");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user && user.username) {
      setUsername(user.username);
      setIsLoggedIn(true);
    }

    const handleUserRegistered = (event) => {
      setUsername(event.detail.username);
      setIsLoggedIn(true);
    };

    window.addEventListener("userRegistered", handleUserRegistered);

    return () => {
      window.removeEventListener("userRegistered", handleUserRegistered);
    };
  }, []);

  const handleLogout = async () => {
    try {
      // 取得したCSRFトークンをクッキーから取得し、ヘッダーに追加
      const csrfToken = Cookies.get("XSRF-TOKEN");

      console.log("CSRF token", csrfToken); // デバッグ用

      // ログアウトリクエストを送信
      await axios.post(
        `${config.apiBaseUrl}/api/logout`,
        {},
        {
          withCredentials: true,
          headers: {
            "X-XSRF-TOKEN": csrfToken,
          },
        }
      );

      // ローカルストレージからユーザー情報を削除
      localStorage.removeItem("user");
      setUsername("ゲスト");
      setIsLoggedIn(false);

      // ログアウト成功後のリダイレクト
      navigate("/login");
    } catch (error) {
      console.error("Logout error", error);
    }
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  return (
    <>
      <header className="App-header">
        <h1>
          <NavLink to="/" onClick={closeMenu}>
            Trend Tagger
          </NavLink>
        </h1>
        <div
          className={`menu-toggle ${menuOpen ? "open" : ""}`}
          onClick={toggleMenu}
        >
          <div className="bar1"></div>
          <div className="bar2"></div>
          <div className="bar3"></div>
        </div>
        <nav className={menuOpen ? "open" : ""}>
          <NavLink
            to="/homepage"
            exact
            activeClassName="active"
            onClick={closeMenu}
          >
            サイト概要
          </NavLink>
          <NavLink to="/" exact activeClassName="active" onClick={closeMenu}>
            投稿ランキング
          </NavLink>
          <NavLink to="/analytics" activeClassName="active" onClick={closeMenu}>
            投稿分析
          </NavLink>
          <NavLink
            to="/popular-hashtags"
            activeClassName="active"
            onClick={closeMenu}
          >
            人気のハッシュタグ
          </NavLink>
          {!isLoggedIn ? (
            <>
              <NavLink
                to="/register"
                exact
                activeClassName="active"
                onClick={closeMenu}
              >
                新規登録
              </NavLink>
              <NavLink
                to="/login"
                exact
                activeClassName="active"
                onClick={closeMenu}
              >
                ログイン
              </NavLink>
            </>
          ) : (
            <button onClick={handleLogout} className="logout-button">
              ログアウト
            </button>
          )}
        </nav>
        <div className="user-info">ユーザー名： {username}</div>
      </header>
      <div className="ad-notice">
        <p>※当サイトには広告が含まれています。</p>
      </div>
    </>
  );
};

export default Header;
