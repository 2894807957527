import React, { useState } from "react";
import axios from "axios";
import "./Auth.css";
import config from "../../config";
import { useNavigate } from "react-router-dom";
import GoogleLoginButton from "./GoogleLoginButton";
import { getCookie } from "../../utils/cookies";

const Register = () => {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationErrors = {};
    const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;

    if (!passwordRegex.test(password)) {
      validationErrors.password =
        "パスワードは英字と数字を含む8文字以上である必要があります。";
    }

    if (password !== passwordConfirmation) {
      validationErrors.passwordConfirmation =
        "パスワードとパスワード確認が一致しません。";
    }

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    try {
      // 1. CSRFトークンを取得
      await axios.get(`${config.apiBaseUrl}/sanctum/csrf-cookie`, {
        withCredentials: true,
      });

      const csrfToken = getCookie("XSRF-TOKEN"); // CSRFトークンを取得
      console.log(csrfToken);

      // 2. 登録リクエストを送信
      const response = await axios.post(
        `${config.apiBaseUrl}/api/register`,
        {
          username,
          email,
          password,
          password_confirmation: passwordConfirmation,
        },
        {
          withCredentials: true,
          withXSRFToken: true,
          headers: {
            "X-XSRF-TOKEN": csrfToken,
            Accept: "application/json", // Acceptヘッダーを追加
            Refer: `${window.location.origin}`,
          },
        }
      );

      // 登録成功時の処理
      console.log("Registration successful", response);

      // ユーザー名を localStorage に保存
      localStorage.setItem("user", JSON.stringify({ username }));

      // カスタムイベントを発火してHeaderに伝える
      const event = new CustomEvent("userRegistered", { detail: { username } });
      window.dispatchEvent(event);

      // 成功ページにリダイレクト
      navigate("/success");
    } catch (error) {
      if (error.response && error.response.data) {
        setErrors(error.response.data.errors || {});
      } else {
        console.error("An unexpected error occurred", error);
      }
    }
  };

  return (
    <div className="auth-container">
      <h2>新規登録</h2>
      <form onSubmit={handleSubmit} className="auth-form">
        <input
          type="text"
          placeholder="ユーザー名"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          required
        />
        <input
          type="email"
          placeholder="メールアドレス"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <input
          type="password"
          placeholder="パスワード"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <input
          type="password"
          placeholder="パスワード確認"
          value={passwordConfirmation}
          onChange={(e) => setPasswordConfirmation(e.target.value)}
          required
        />
        {Object.keys(errors).length > 0 && (
          <div className="error-message">
            {Object.values(errors).map((error, index) => (
              <p key={index}>{error}</p>
            ))}
          </div>
        )}
        <button type="submit">登録</button>
      </form>

      <div className="social-login">
        <p>またはソーシャルアカウントで登録</p>
        <GoogleLoginButton />
      </div>
    </div>
  );
};

export default Register;
