import React from "react";
import "./AdCard.css"; // 必要ならスタイルシートを作成する

const AdCard = ({ adHtml, isPopularPostPage }) => {
  return (
    <div
      className={`ad-card ${isPopularPostPage ? "post-card" : ""}`}
      dangerouslySetInnerHTML={{ __html: adHtml }}
    ></div>
  );
};

export default AdCard;
