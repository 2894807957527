import React from "react";
import { Link } from "react-router-dom";
import "./Success.css";

const Success = () => {
  return (
    <div className="success-container">
      <h2>登録が完了しました！</h2>
      <p className="success-explain">
        ご登録ありがとうございます。<br></br>以下のリンクからサイトの機能をお楽しみください。
      </p>
      <div className="success-buttons">
        <Link to="/" className="success-button">
          投稿ランキングを見る
        </Link>
        <Link to="/analytics" className="success-button">
          投稿分析を見る
        </Link>
        <Link to="/popular-hashtags" className="success-button">
          人気のハッシュタグを見る
        </Link>
      </div>
    </div>
  );
};

export default Success;
