import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import { Line } from "react-chartjs-2";
import "chartjs-adapter-date-fns";
import { ja } from "date-fns/locale";
import { Chart, registerables } from "chart.js";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import config from "../../config";
import "./AnalyticsChart.css";
import { HashtagContext } from "../../context/HashtagContext";
import LoadingIndicator from "../LoadingIndicator/LoadingIndicator";
import NoResultsMessage from "../NoResultsMessage/NoResultsMessage";
import { getCookie } from "../../utils/cookies";

Chart.register(...registerables);

const HourlyAnalyticsChart = () => {
  const { hashtag, date } = useParams();
  const [chartData, setChartData] = useState({ datasets: [] });
  const [loading, setLoading] = useState(true);
  const [authError, setAuthError] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const previousState = location.state;
  const { setRetainSearchState } = useContext(HashtagContext);

  useEffect(() => {
    if (hashtag && date) {
      const fetchData = async () => {
        try {
          setLoading(true);
          setAuthError(false);

          // CSRFトークンを取得
          await axios.get(`${config.apiBaseUrl}/sanctum/csrf-cookie`, {
            withCredentials: true,
          });

          const csrfToken = getCookie("XSRF-TOKEN");
          const isDefaultHashtag = config.defaultHashtags.includes(hashtag);
          const url = isDefaultHashtag
            ? `${config.apiBaseUrl}/api/hourly-analytics/${encodeURIComponent(
                hashtag
              )}/${date}`
            : `${
                config.apiBaseUrl
              }/api/hourly-analytics/secure/${encodeURIComponent(
                hashtag
              )}/${date}`;
          console.log("Fetching from:", url);

          const response = await axios.get(url, {
            withCredentials: true,
            headers: {
              "X-XSRF-TOKEN": csrfToken,
              Accept: "application/json",
            },
          });

          console.log("API data:", response.data);

          const hours = Array.from(
            { length: 24 },
            (_, i) =>
              new Date(`${date}T${String(i).padStart(2, "0")}:00:00+09:00`)
          );

          const likeCounts = Array(24).fill(0);
          const postCounts = Array(24).fill(0);

          response.data.forEach((item) => {
            const hour = new Date(item.datetime).getHours();
            likeCounts[hour] = item.like_count;
            postCounts[hour] = item.post_count;
          });

          setChartData({
            labels: hours,
            datasets: [
              {
                label: "いいね数",
                data: likeCounts,
                borderColor: "rgba(75, 192, 192, 1)",
                fill: false,
                yAxisID: "y1",
                pointRadius: 6,
                pointHoverRadius: 8,
                pointStyle: "circle",
              },
              {
                label: "投稿数",
                data: postCounts,
                borderColor: "rgba(255, 99, 132, 1)",
                fill: false,
                yAxisID: "y2",
                pointRadius: 6,
                pointHoverRadius: 8,
                pointStyle: "circle",
              },
            ],
          });
        } catch (error) {
          if (error.response && error.response.status === 401) {
            setAuthError(true);
          }
          console.error("Error fetching data:", error);
        } finally {
          setLoading(false);
        }
      };

      fetchData();
    }
  }, [hashtag, date]);

  const handleBackButtonClick = () => {
    setRetainSearchState(true);
    navigate("/analytics", { state: previousState });
  };

  return (
    <div className="analytics-charts hourly-analytics-charts">
      {loading ? (
        <LoadingIndicator />
      ) : authError ? (
        <NoResultsMessage message="ログインが必要です。ログインまたは新規登録をしてください。" />
      ) : (
        <div className="chart-container">
          <h2>
            ハッシュタグ： {hashtag} - {date}
          </h2>
          <div className="chart-wrapper">
            <Line
              data={chartData}
              options={{
                scales: {
                  x: {
                    type: "time",
                    time: {
                      unit: "hour",
                      tooltipFormat: "MMM d, yyyy HH:mm",
                      displayFormats: {
                        hour: "HH:mm",
                      },
                      adapters: {
                        date: {
                          locale: ja,
                        },
                      },
                      min: new Date(`${date}T00:00:00+09:00`),
                      max: new Date(`${date}T23:59:59+09:00`),
                    },
                    title: {
                      display: true,
                      text: "投稿時間",
                    },
                    ticks: {
                      callback: function (value, index, values) {
                        const date = new Date(value);
                        const formattedDate = date.toLocaleTimeString("ja-JP", {
                          hour: "2-digit",
                          minute: "2-digit",
                        });
                        return formattedDate;
                      },
                    },
                  },
                  y1: {
                    type: "linear",
                    position: "left",
                    title: {
                      display: true,
                      text: "いいね数",
                    },
                    ticks: {
                      color: "rgba(75, 192, 192, 1)",
                    },
                  },
                  y2: {
                    type: "linear",
                    position: "right",
                    title: {
                      display: true,
                      text: "投稿数",
                    },
                    grid: {
                      drawOnChartArea: false,
                    },
                    ticks: {
                      color: "rgba(255, 99, 132, 1)",
                    },
                  },
                },
                plugins: {
                  legend: {
                    display: true,
                    position: "top",
                  },
                },
                responsive: true,
                maintainAspectRatio: false,
              }}
              style={{ height: "100%", width: "100%" }}
            />
          </div>
        </div>
      )}
      <button onClick={handleBackButtonClick} className="back-button">
        戻る
      </button>
    </div>
  );
};

export default HourlyAnalyticsChart;
