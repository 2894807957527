import React, { useState } from "react";
import axios from "axios";
import "./Auth.css";
import config from "../../config";
import GoogleLoginButton from "./GoogleLoginButton";
import { useNavigate } from "react-router-dom";
import { getCookie } from "../../utils/cookies";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // 1. CSRFトークンを取得
      await axios.get(`${config.apiBaseUrl}/sanctum/csrf-cookie`, {
        withCredentials: true,
      });

      const csrfToken = getCookie("XSRF-TOKEN"); // CSRFトークンを取得
      console.log(csrfToken);

      // 2. ログインリクエストを送信
      const response = await axios.post(
        `${config.apiBaseUrl}/api/login`,
        { email, password },
        {
          withCredentials: true,
          withXSRFToken: true,
          headers: {
            "X-XSRF-TOKEN": csrfToken,
            Accept: "application/json", // Acceptヘッダーを追加
            Refer: `${window.location.origin}`,
          },
        }
      );

      // ログイン成功時の処理
      console.log("Login successful", response.data);

      // レスポンスからユーザー名を取得し、localStorageに保存
      const { username } = response.data.user;
      console.log("Response data", response.data);

      if (username) {
        localStorage.setItem("user", JSON.stringify({ username }));

        // カスタムイベントを発火してHeaderに伝える
        const event = new CustomEvent("userRegistered", {
          detail: { username },
        });
        window.dispatchEvent(event);

        // ホームページにリダイレクト
        navigate("/");
      } else {
        console.error("Username not found in response data.");
      }
    } catch (error) {
      // ログイン失敗時の処理
      console.error("Login error", error);

      if (error.response && error.response.data.message) {
        setErrorMessage(error.response.data.message);
      } else {
        setErrorMessage("An unexpected error occurred.");
      }
    }
  };

  return (
    <div className="auth-container">
      <h2>ログイン</h2>
      {errorMessage && <p className="error-text">{errorMessage}</p>}
      <form onSubmit={handleSubmit} className="auth-form">
        <input type="hidden" name="_token" value={getCookie("XSRF-TOKEN")} />
        <input
          type="email"
          placeholder="メールアドレス"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <input
          type="password"
          placeholder="パスワード"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <button type="submit">ログイン</button>
      </form>

      <div className="social-login">
        <p>またはソーシャルアカウントでログイン</p>
        <GoogleLoginButton />
      </div>
    </div>
  );
};

export default Login;
