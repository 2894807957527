// src/App.js

import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import WeeklyAnalyticsChart from "./components/Analytics/WeeklyAnalyticsChart";
import HourlyAnalyticsChart from "./components/Analytics/HourlyAnalyticsChart";
import PopularPosts from "./components/Posts/PopularPosts";
import PopularHashtags from "./components/Hashtags/PopularHashtags";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import ScrollToTopButton from "./components/ScrollToTopButton/ScrollToTopButton";
import { HashtagProvider } from "./context/HashtagContext";
import "./App.css";
import HomePage from "./components/HomePage/HomePage";
import PrivacyPolicy from "./components/PrivacyPolicy/PrivacyPolicy";
import Register from "./components/auth/Register";
import Login from "./components/auth/Login";
import Success from "./components/auth/Success";

const App = () => {
  return (
    <Router>
      <HashtagProvider>
        <AppContent />
      </HashtagProvider>
    </Router>
  );
};

const AppContent = () => {
  return (
    <div className="App">
      <Header />
      <main>
        <Routes>
          <Route path="/" element={<PopularPosts />} />
          <Route path="/analytics" element={<WeeklyAnalyticsChart />} />
          <Route
            path="/hourly/:hashtag/:date"
            element={<HourlyAnalyticsChart />}
          />
          <Route path="/popular-hashtags" element={<PopularHashtags />} />
          <Route path="homepage" element={<HomePage />} />
          <Route path="privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/register" element={<Register />} />
          <Route path="/login" element={<Login />} />
          <Route path="/success" element={<Success />} />
        </Routes>
      </main>
      <Footer />
      <ScrollToTopButton />
    </div>
  );
};

export default App;
